//=================================================
// Contacts
//=================================================

.contact-me-img {
	background-repeat: no-repeat;
	background-position: center right;
	background-size: contain;
}

.contacts-information {
	@extend .style-default;
	width: 100%;
	max-width: 38.75rem;

	.contacts-information-body {
		position: relative;

		.contacts-information-dot {
			position: absolute;
			top: 0;
			left: 50%;
			@include transform(translateY(-50%));
			height: 9px;
			width: 9px;
			border-radius: 50%;
			background-color: $body_main_element_primary_color;
		}

		.contacts-information-items {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-between;
			border-top: 1px solid $body_border_color;
			padding-top: $space_sm;
			margin-bottom: -$space_sm;

			@media #{$media_xl} {
				-ms-flex-pack: start !important;
				justify-content: flex-start !important;
			}

			.contacts-information-item {
				padding-right: $space_sm;
				padding-bottom: $space_sm;

				&:last-child {
					padding-right: 0;
				}

				@media #{$media_lg} {
					flex: 0 0 50%;
					max-width: 50%;
				}

				@media #{$media_md} {
					flex: 0 0 50%;
					max-width: 50%;
				}

				.contacts-information-item-title {
					color: $body_headline_color;
					margin-bottom: $space_xs;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}
